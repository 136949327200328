import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import parse from "html-react-parser";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Navigation from "../components/navigation";

const PolicyTemplate = ({ data }) => {
	const { wpPolicy } = data;
	const { siteUrl } = data.site.siteMetadata;

	return (
		<Layout>
			<div className="bg-med-grey">
				<GatsbySeo
					title={`${wpPolicy.title} | QuoteStar`}
					description={wpPolicy.title}
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}${wpPolicy.uri}`,
						title: `${wpPolicy.title} | QuoteStar`,
						description: `${wpPolicy.title}`,
					}}
				/>

				<Container className="py-6">
					<Row className="justify-content-center">
						<Col lg={10}>
							<div
								style={{ borderRadius: "12px" }}
								className="bg-white p-md-5 p-2"
							>
								<Row>
									<Col>
										<h1 className="display-4 text-center pb-5">
											{wpPolicy.title}
										</h1>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className="policy blog-content px-3 px-lg-0">
											{parse(wpPolicy.content)}
										</div>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</Layout>
	);
};

export default PolicyTemplate;

export const blogData = graphql`
	query ($id: String!) {
		wpPolicy(id: { eq: $id }) {
			title
			content
			uri
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
